/*src/css/styles.css*/

/* Basic reset for styling */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: 'BagnardSans';
    src: url('../fonts/BagnardSans.otf') format('opentype'); 
    font-weight: normal;
    font-style: normal;
}

body, html {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: BagnardSans, Arial, sans-serif;
    background-color: #333;
    color: #fff;
}

/* Global link styles */
a {
    color: #3498db;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Link styles within the credit section */
#background-music-Credit {
    text-align: center;
    margin: 10px 0;
}


/* Button styling */

/* Specific button variations */


/* Header styling */
header {
    background-size: cover;
    text-align: center;
    position: relative;
    color: #fff;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
}

.top-left-controls {
    position: absolute;
    left: 10px;
    padding: 20px;
}

.logo {
    width: 40px;
    height: auto;
    vertical-align: middle;
}

.status {
    margin-top: 10px;
    font-size: 0.9em;
    color: #FFD700;
}

.center-content {
    flex-grow: 1;
    text-align: center;
}

/* Icon styling */
.icon {
    position: absolute;
    top: 25%;
    right: 25%;
    width: 50%;
    height: 50%;
    z-index: 3;
    user-select: none;
    pointer-events: none;
}

/* Sound container and buttons */
.sound-button {
    flex-basis: calc(33.333% - 10px);
    text-align: center;
}

.button {
    flex: 1 0 auto;
    text-align: center;
    text-transform: capitalize;
}




/* Navigation styling */
nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #222;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
}

nav a {
    color: white;
    text-decoration: none;
    padding: 10px;
    display: block;
}

/* Modal styling */
.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    background-color: transparent;
    padding: 0;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    overflow: hidden;
}

.close-button {
    color: #aaa;
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    z-index: 1001;
}

.close-button:hover,
.close-button:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

#external-modal-body {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #444;
}


/* Dropdown menu styling */
.menu-dropdown {}


/* Credit modal styling */
.credit-modal-template,
.all-credits-modal {}

.credit-modal-template {}

.credit-content {
    text-align: left;
}

.close-btn {}

.all-credits-modal {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
}

/* Edit sound modal */
.edit-sound-modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow-y: auto;
}

.edit-sound-modal .modal-header {
    color: white;
    padding: 10px;
    text-align: center;
}

.edit-sound-modal .modal-body {
    padding: 20px;
    color: #acacac;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* Sound item and controls */
.type-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.sound-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sound-item {
    flex: 1;
    margin: 10px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
}

.sound-player-controls {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    position: relative;
}

.sound-player-controls button {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: transparent;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 1;
}

.sound-player-controls button:hover {
    background-color: #0056b3;
}

.sound-player-controls .icon {
    font-size: 18px;
    position: static;
}

.sound-player-controls input[type="range"] {
    width: 100px;
}

.update-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.update-button:hover {}

/* Audio controls */
#audio-controls,
#background-audio {
    width: 300px;
    margin: 0 auto;
}

.audio-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
}

/* Progress bar and next button */
.progress-and-credits {}

#nextButton {
    padding: 0 10px;
    font-size: 20px;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.3s;
}

#nextButton:hover,
#nextButton:focus {}

/* Preset controls */
#preset-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#input-button-container {
    display: flex;
    align-items: center;
}


#save-preset-button {
    width: 25px;
    height: 25px;
    font-size: 20px;
    cursor: pointer;
    border: none;
    color: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

#save-preset-button:hover {}

#preset-dropdown {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin: 0 auto;
}

/* Footer styling */
footer {
    padding: 20px 0;
}

/* Session Management Styling */
.id-management {
    background-color: #222;
    border: 1px solid #444;
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
    color: #fff;
}

.id-management h2 {
    margin-top: 0;
    margin-bottom: 15px;
    text-align: center;
    color: #fff;
}

.create-id, .join-id {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#disconnect-button {
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 16px;
    margin: 0 5px;
    transition: background-color 0.3s;
}

#disconnect-button:hover {}


#join-id-input {
    padding: 10px;
    width: 200px;
    margin-right: 10px;
    border-radius: 5px;
}

#join-id-input::placeholder {
    color: #bbb;
}

#status-message {
    text-align: center;
    color: #FFD700; /* Gold color for visibility */
    margin-top: 10px;
}

#your-id {
    /*text-align: center;*/
    /*margin-top: 10px;*/
    /*color: #fff;*/
}

#your-id span {
    /*font-weight: bold;*/
    /*color: #4CAF50;*/
}

#connection-status {
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    #background-music-credit a {
        @apply text-violet-700 hover:underline font-semibold mb-0;
    }

    #credit-details a {
        @apply text-violet-700 hover:underline font-semibold;
    }
    #credit-details .title {
        @apply font-bold text-xl cursor-auto;
    }
    /* Badge */
    .blueBadge {
        @apply bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm dark:bg-blue-900 dark:text-blue-300
    }

    .purpleBadge {
        @apply bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm dark:bg-purple-900 dark:text-purple-300
    }

    /* Buttons */
    .button {
        @apply bg-transparent hover:bg-slate-700 font-medium text-slate-900 hover:text-slate-100 py-2 px-4 border border-slate-900 hover:border-transparent rounded;
        @apply transition-colors duration-300 ease-in-out; /* Smooth transition */
    }

    .buttonOutlined {
        @apply bg-transparent hover:bg-slate-700 font-medium text-slate-900 hover:text-slate-100 py-2 px-4 border border-slate-900 hover:border-transparent rounded;
        @apply transition-colors duration-300 ease-in-out; /* Smooth and slower transition */
    }

    .buttonOutlinedLight {
        @apply bg-transparent hover:bg-slate-100 font-medium text-slate-100 hover:text-slate-900 py-2 px-4 border border-slate-100 hover:border-transparent rounded;
        @apply transition-colors duration-300 ease-in-out; /* Smooth and slower transition */
    }

    .buttonOutlinedLightRound {
        @apply bg-transparent hover:bg-slate-100 font-medium text-slate-100 hover:text-slate-900 border border-slate-100 hover:border-transparent rounded-full border-2 text-center;
        @apply transition-colors duration-300 ease-in-out; /* Smooth and slower transition */
    }

    .buttonOutlined:disabled {}

    .button {
        @apply bg-transparent bg-slate-700 font-medium text-slate-100 py-2 px-4 border border-transparent rounded;
        @apply transition-colors duration-300 ease-in-out; /* Smooth transition */
    }

    .button:hover {
        @apply bg-purple-700 text-slate-100 border-purple-700; /* Hover effect */
    }

    .button:disabled {}

    .disabledMusicButton {
        @apply pointer-events-none opacity-50 cursor-not-allowed;
    }
    /* End of buttons */

    /* Input */
    .input-text {
        @apply px-4 py-2 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-purple-600 focus:outline-none;
    }
    /* End of input */

    .neon {
        @apply bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 bg-[length:200%_200%] bg-clip-text text-transparent;
    }

    /* Slider Track */
    .slider {
        @apply appearance-none w-full h-1.5 rounded-full;
            background: linear-gradient(to right, #ec4899 0%, #a855f7 50%, #3b82f6 50%, #e2e8f0 50%);
    }

    /* Slider Thumb */
    .slider::-webkit-slider-thumb {
        @apply appearance-none h-4 w-4 border-2 rounded-full bg-purple-500 shadow-lg cursor-pointer;
    }

    .slider::-moz-range-thumb {
        @apply appearance-none h-4 w-4 border-2 rounded-full bg-purple-500 shadow-lg cursor-pointer;
    }

    .slider::-ms-thumb {
        @apply appearance-none h-4 w-4 border-2 rounded-full bg-purple-500 shadow-lg cursor-pointer;
    }

    .menu-content {
        @apply bg-slate-100 border border-gray-300 absolute top-20 right-0 z-10 w-40
               transition duration-700 ease-in-out opacity-0 scale-95 hidden;
    }

    .menu-item-google {
        @apply px-0 py-0  w-full
    }

    .menu-content.show {
        @apply opacity-100 scale-100 block;
    }

    .menu-item {
        @apply w-full px-4 py-3 text-gray-700 bg-slate-100 rounded-md shadow-md hover:bg-gray-300;
    }

    .menu-divider {
        @apply w-full h-[1px] bg-gray-300;
    }

    .sound-container {
        @apply flex flex-wrap justify-center gap-2.5;
    }

    /* Progress bar styling */
     .progress-bar-container {
        @apply relative w-full h-[100px] overflow-hidden bg-cover bg-center rounded-md;
    }

    .progress-bar {
        @apply bg-violet-600 w-0 h-full opacity-35;
        transition: width 0.3s ease;
    }

    .progress-bar-overlay {
        @apply absolute inset-0 bg-black opacity-35;
    }

    /* Progress bar volume controls */
    .progress-bar-volume {
        @apply block absolute top-0 bottom-0 bg-violet-800 cursor-pointer;
        opacity: 0%; /* Réduit globalement l'opacité */
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .progress-bar-volume:hover {
        @apply transition-opacity duration-500 opacity-50;
    }

    .progress-bar-volume.plus,
    .progress-bar-volume.minus {
        @apply absolute;
    }

    .progress-bar-volume.plus {
        @apply left-1/2 right-0;
    }

    .progress-bar-volume.minus {
        @apply left-0 right-1/2;
    }

    .progress-bar-volume.minus:before,
    .progress-bar-volume.plus:before,
    .progress-bar-volume.plus:after {
        @apply content-[''] block bg-white absolute h-0.5 w-5 opacity-100;
    }

    .progress-bar-volume.minus:before {
        @apply top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-100;
    }

    .progress-bar-volume.plus:before {
        @apply top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-100;
    }

    .progress-bar-volume.plus:after {
        @apply top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-90;
    }
    /* End of progress bar styling */


    /* Section styling */
    .section {
        @apply grid grid-cols-6 grid-flow-row-dense gap-5 p-5 bg-slate-950 relative;
        @apply grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6;
    }

    .section-title {
        @apply text-[12px] font-medium tracking-widest text-white px-5 py-1 rounded shadow-md top-0 left-0 bg-slate-950;
    }
    /* End of section styling */

    /* SoundBoard styling */
    .buttonStyle {
        @apply hover:text-violet-700 text-slate-700
    }

    .section-sound-board {
        @apply grid-flow-row gap-5 p-5;
        @apply grid-cols-5 sm:grid-cols-7 md:grid-cols-9 lg:grid-cols-11 xl:grid-cols-12
    }

    .sound-board-button {
        @apply bg-transparent h-14 w-14 border border-2 hover:bg-violet-900 text-slate-100
        font-medium text-[11px] shadow-md transition-all duration-200 transform hover:scale-105 rounded-full
        text-center;
    }

    .button-play {
        @apply animate-neon-glow-intense;
    }

    .button-loop {

    }
    /* SoundBoard section styling */

    /* Preset section styling */
    .preset-button {
        @apply bg-transparent h-14 border border-2 hover:bg-violet-900 text-slate-100
        font-medium text-[11px] shadow-md transition-all duration-200 transform hover:scale-105 rounded-full
        text-center truncate;
    }
    /* End of Preset section styling */

    /* Ambient Sound and card styling */
    .sound-bar-text {
        @apply absolute left-1/2 transform -translate-x-1/2 z-0 text-center text-slate-100 font-semibold font-lato select-none pointer-events-none top-1 w-4/5;
    }

    .sound-bar-licence {
        @apply absolute left-1/2 transform -translate-x-1/2 z-0 text-center text-slate-100 font-bold font-lato select-none pointer-events-none bottom-1 w-2/5 h-[15px] text-[10px] ;
    }
    /* End of Ambient Sound and card styling */

    .footer-content {
        @apply text-center text-slate-100 leading-tight;
    }

    .footer-content a {
        @apply mx-4 text-slate-100 leading-tight;
    }

    .footer-content a:hover {
        @apply underline cursor-pointer duration-300;
    }

}
